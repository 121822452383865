.contact {
    .bg {
        background-color: var(--main-color);
        height: 100vh;
        left: 0;
        position: fixed;
        top: 0;
        clip-path: polygon(0 0, 100% 0, 7% 7%, 0% 100%);
        width: 100%;
        z-index: -9999;
        opacity: .4;
    }

    .wrap {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        padding: 0 4rem;

        @media screen and (max-width: 1200px) {
            padding: 0 2rem;
        }

        @media screen and (max-width: 500px) {
            padding: 0 1rem;
        }

        .info {
            width: 40%;
            cursor: default;

            @media screen and (max-width: 1200px) {
                width: 100%;
            }

            color: var(--txt-color);

            h2 {
                margin: 0;
                text-transform: uppercase;
                font-size: 30px;
                font-weight: 800;
            }

            .detail {
                font-weight: 600;
                line-height: 25px;
                margin-bottom: 20px;
                padding-left: 50px;
                position: relative;
                margin-top: 2rem;

                .icon {
                    color: var(--main-color) !important;
                    font-size: 40px;
                    left: 0;
                    position: absolute;
                    top: 0;
                }

                .name {
                    display: block;
                    font-weight: 400;
                    opacity: .8;
                    padding: 0;
                    text-transform: uppercase;
                }

                .content {
                    text-transform: capitalize;

                    a {
                        text-decoration: none;
                        text-transform: lowercase;
                        color: var(--txt-color);

                        &:hover {
                            color: var(--main-color);
                        }
                    }
                }
            }
        }

        .mail {
            width: 60%;

            @media screen and (max-width: 1200px) {
                width: 100%;
                padding-bottom: 6rem;
            }

            color: var(--txt-color);

            input {
                background-color: var(--nav-bg-color);
                border: 1px solid var(--nav-bg-color);
                border-radius: 30px;
                color: var(--txt-color);
                font-size: 15px;
                outline: none !important;
                padding: 1.5rem 2rem;
                transition: all .3s ease;
                margin-top: 2rem;
            }

            .name-email {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;

                input {
                    width: 38%;

                    @media screen and (max-width: 700px) {
                        width: 100%;
                    }
                }
            }

            .subject {
                width: 100%;
                display: flex;
                flex-wrap: wrap;

                input {
                    width: 100%;
                }
            }

            .message {
                width: 100%;
                display: flex;
                flex-wrap: wrap;

                textarea {
                    width: 100%;
                    background-color: var(--nav-bg-color);
                    border: 1px solid var(--nav-bg-color);
                    border-radius: 30px;
                    color: var(--txt-color);
                    font-size: 15px;
                    outline: none !important;
                    padding: 1.5rem 2rem;
                    transition: all .3s ease;
                    margin-top: 2rem;
                    min-height: 10rem;
                }
            }

            .button {
                margin-top: 2rem;

                button {
                    cursor: pointer;
                    font-family: inherit;
                    font-size: 20px;
                    background: var(--main-color);
                    color: white;
                    padding: 0.7em 1em;
                    padding-left: 0.9em;
                    display: flex;
                    align-items: center;
                    border: none;
                    border-radius: 30px;
                    overflow: hidden;
                    transition: all 0.2s;

                    span {
                        display: block;
                        margin-left: 0.3em;
                        transition: all 0.3s ease-in-out;
                    }

                    svg {
                        display: block;
                        transform-origin: center center;
                        transition: transform 0.3s ease-in-out;
                    }

                    &:hover .svg-wrapper {
                        animation: fly-1 0.6s ease-in-out infinite alternate;
                    }

                    &:hover svg {
                        transform: translateX(1.2em) rotate(45deg) scale(1.1);
                    }

                    &:hover span {
                        transform: translateX(5em);
                    }

                    &:active {
                        transform: scale(0.95);
                        -webkit-transform:scale(0.95);
                    }

                    @keyframes fly-1 {
                        from {
                            transform: translateY(0.1em);
                        }

                        to {
                            transform: translateY(-0.1em);
                        }
                    }
                }
            }
        }

        .qrcode {
            width: 60%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 2rem;

            @media screen and (max-width: 1200px) {
                width: 100%;
                padding-bottom: 6rem;
            }

            .wrap {
                background-color: var(--main-color);
                color: white;
                display: block;
                border: 4px solid var(--main-color);
                box-shadow: 0 0 7px rgb(0 0 0 / 90%);
                border-radius: 30px;
                padding: 10px;

                .img {
                    width: 18rem;
                    height: 18rem;
                    display: block;

                    div {
                        width: 100%;
                        height: 100%;
                        background-position: 50%;
                        background-repeat: no-repeat;
                        background-size: cover;
                    }
                }

                .scan {
                    text-align: center;
                    margin-top: 12px;
                    font-weight: 700;
                    font-size: 2.5rem;
                    text-transform: uppercase;
                }
            }

            .button {
                margin-top: 2rem;
                display: flex;
                justify-content: center;

                button {
                    cursor: pointer;
                    font-family: inherit;
                    font-size: 20px;
                    background: var(--main-color);
                    color: white;
                    padding: 0.7em 1em;
                    padding-left: 0.9em;
                    display: flex;
                    align-items: center;
                    border: none;
                    border-radius: 30px;
                    overflow: hidden;
                    transition: all 0.2s;

                    span {
                        display: block;
                        margin-left: 0.3em;
                        transition: all 0.3s ease-in-out;
                    }

                    i {
                        display: block;
                        transform-origin: center center;
                        transition: transform 0.3s ease-in-out;
                        transform: rotate(-90deg);
                    }

                    &:hover .svg-wrapper {
                        animation: fly-1 0.6s ease-in-out infinite alternate;
                    }

                    &:hover i {
                        transform: translateX(2.4em) rotate(0deg) scale(1.1);
                    }

                    &:hover span {
                        transform: translateX(7em);
                    }

                    &:active {
                        transform: scale(0.95);
                        -webkit-transform:scale(0.95);
                    }

                    @keyframes fly-1 {
                        from {
                            transform: translateY(0.1em);
                        }

                        to {
                            transform: translateY(-0.1em);
                        }
                    }
                }
            }
        }
    }
}