.project {
    padding-bottom: 5rem;
    .bg {
        background-color: var(--main-color);
        height: 100vh;
        left: 0;
        position: fixed;
        top: 0;
        clip-path: polygon(0 0, 100% 0, 100% 100%, 95% 10%);
        width: 100%;
        z-index: -9999;
        opacity: .4;
    }

    .tab {
        display: flex;
        justify-content: center;
        align-items: center;
        // position: sticky;
        top: 10px;
        z-index: 3;

        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;
            text-align: center;

            li {
                cursor: pointer;
                display: inline-block;
                border: none;
                border-radius: 12px;
                margin: 0 .6rem;
                background-color: var(--nav-bg-color);
                padding: .5rem 1rem;
                color: var(--reverse-color);
                font-weight: bold;
                text-transform: uppercase;
                transition: all .5s;
                width: fit-content;

                @media screen and (max-width: 760px) {
                    width: 32%;
                    transition: all .5s;
                    margin-top: 1rem;
                    padding: .5rem 1.5rem;
                    word-wrap: break-word;
                }

                &:hover {
                    background-color: var(--main-color);
                }

                .count{
                    transition: all .5s;
                    background-color: var(--notif-color);
                    color: white;
                    border-radius: 50%;
                    padding: 1px 5px;
                    font-size: small;
                    text-align: center;

                }
            }

            .active {
                background-color: var(--main-color);
            }
        }
    }

    .projects {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        gap: 30px;
        margin-top: 3rem;
        padding: 0;
        transition: all .5s;
        
        @media screen and (max-width: 1200px) {
            padding: 0 1rem;
            transition: .5s;
        }

        .project-wrap {
            border-radius: 8px;
            overflow: hidden;
            position: relative;
            // height: 18rem;
            cursor: pointer;
            width: calc(33.33333% - 20px);
            margin-bottom: .2rem;
            // background-color: var(--nav-bg-color);
            background-image: url(https://static.vecteezy.com/system/resources/thumbnails/008/034/405/small/loading-bar-doodle-element-hand-drawn-vector.jpg);
            background-size: 100% 100%;
            background-repeat: no-repeat;

            @media screen and (max-width: 992px) {
                width: calc(50% - 15px);
            }

            @media screen and (max-width: 760px) {
                width: 100%;
            }
            .img{
                height: 25vh;
            }
            img {
                background-position: 50%;
                background-repeat: no-repeat;
                background-size: cover;
                width: 100%;
                height: 100%;
                display: block;
            }

            .name {
                position: absolute;
                color: #fff;
                font-size: 24px;
                font-weight: 600;
                left: 50%;
                opacity: 0;
                text-transform: uppercase;
                top: 50%;
                transform: translate(-50%, -30%);
                transition: opacity .5s ease, visibility .5s ease, transform .5s ease, -webkit-transform .5s ease;
                visibility: hidden;
                z-index: 2;
                text-align: center;
            }

            .bg-hover {
                position: absolute;
                background-color: black;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                opacity: 0;
                visibility: hidden;
                transition: all .5s;
                border-radius: 8px;
            }

            &:hover {
                .name {
                    visibility: visible;
                    opacity: 1;
                }

                .bg-hover {
                    opacity: .6;
                    visibility: visible;
                }
            }
        }
    }
}

.modal {
    display: none;
    position: fixed;
    z-index: 0;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
    transition: all .5s;
    overflow-x: hidden;

    .content {
        position: relative;
        // background-color: #252525;
        background-color: var(--nav-bg-color);
        display: flex;
        justify-content: space-between;
        padding: 30px;
        max-width: 88vw;
        max-height: 75vh;
        border-radius: 8px;
        width: 100%;

        @media screen and (max-width: 500px) {
            padding: 15px;
        }
        .wrab {
            display: flex;
            justify-content: space-between;

            overflow-y: scroll;
            overflow-x: hidden;

            @media screen and (max-width: 1200px) {
                display: block;
            }
        }

        h3{
            margin: 15px 0;
        }

        ::-webkit-scrollbar {
            width: 0;
        }


        .close {
            cursor: pointer;
            align-items: center;
            -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
            background-color: var(--main-color);
            border-radius: 50%;
            box-shadow: 0 0 7px rgb(0 0 0 / 90%);
            display: flex;
            height: 46px;
            justify-content: center;
            position: absolute;
            right: -22px;
            top: -22px;
            width: 46px;
            font-size: 40px;
            color: white;
        }

        .swiper{
            // height: 40vh;
            margin-bottom: 1rem;
            --swiper-navigation-color: var(--main-color);
            --swiper-pagination-color: var(--main-color);

            .slide{
                height: unset;
            }
            .wrap{
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100% !important;
                background-image: url(https://static.vecteezy.com/system/resources/thumbnails/008/034/405/small/loading-bar-doodle-element-hand-drawn-vector.jpg);
                background-size: 100% 100%;
                background-repeat: no-repeat;

                .img{
                    width: 100%;
                    height: 60vh;
                    @media screen and (max-width: 1200px) {
                        height: 50vh;
                    }
                    @media screen and (max-width: 768px) {
                        height: 30vh;
                    }
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                }
            }
        }
        .mySwiper{
            height: 10vh;
        }

        .detail {
            width: calc(50% - 15px);

            @media screen and (max-width: 1200px) {
                width: 100%;
            }

            h3 {
                cursor: default;
                border-bottom: 3px solid var(--main-color);
                color: var(--main-color);
                font-size: 33px;
                margin-bottom: 1rem rem;
                text-transform: uppercase;
                padding-bottom: 8px;
            }

            .lib {
                max-height: 70vh;

                .swiper {
                    max-height: 70vh;

                    img {
                        height: 100%;
                        width: 100%;
                    }
                }
            }

            .info {
                cursor: default;
                color: var(--reverse-color);

                p {

                    margin-bottom: 10px;
                    text-transform: capitalize;

                    .auth{
                        text-transform: none;
                    }
                    font-weight: 400;

                    span:first-child {
                        font-weight: 300;
                    }
                    .link-demo{
                        text-decoration: none;
                        color: var(--main-color);
                        cursor: pointer;
                        transition: all .5s;
                        text-transform: lowercase;

                        &:hover{
                            font-size: large;
                        }
                    }
                }
                .info-detail{
                    text-transform: none;
                }
            }
        }
    }


}

.modal-active {
    display: flex;
    transition: all .5s;
    justify-content: center;
    align-items: center;
    z-index: 5;
}