.home {
    @media screen and (min-width: 1200px) {
        .bg {
            background-color: var(--main-color);
            height: 200%;
            left: -83%;
            position: fixed;
            top: -50%;
            -webkit-transform: rotate(-15deg);
            transform: rotate(-15deg);
            width: 100%;
            z-index: -9999;
        }
    }

    width: 100%;
    height: 100%;
    display: flex;

    @media screen and (max-width: 1200px) {
        display: block;
    }

    // @media screen and (max-width: 500px) {
    //     padding-bottom: 13rem;
    // }

    .img {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 14rem;

        position: relative;
        @media screen and (min-width: 1200px) {
            height: 100%;
            width: 33.33%;
            padding: 0 2rem;
        }

        @media screen and (max-width: 1200px) {
            .img-bg {
                position: absolute;
                clip-path: ellipse(60% 20% at 50% 0%);
                left: 0;
                top: 0;
                height: 50rem;
                width: 100%;
                background-color: var(--main-color);
                z-index: -99999;
            }
            margin-bottom: 5rem;
        }
        
        .img-link {
            aspect-ratio: 7/10;
            background-color: #000;
            background-image: url('https://static.vecteezy.com/system/resources/thumbnails/008/034/405/small/loading-bar-doodle-element-hand-drawn-vector.jpg');
            background-position: 50%;
            background-repeat: no-repeat;
            background-size: cover;
            border-radius: 30px;
            box-shadow: 0 0 7px rgb(0 0 0 / 90%);
            max-height: 100%;
            width: 100%;
            border: 4px solid var(--main-color);

            @media screen and (max-width: 1200px) {
                width: 14rem;
                height: 14rem;
                border-radius: 50%;
                position: absolute;
                top: 2rem;
            }
        }
    }

    .info {
        align-items: center;
        display: flex;
        height: 100%;
        justify-content: center;
        width: 66.67%;

        @media screen and (max-width: 1200px) {
            display: block;
            width: 100%;
            height: 50%;
            text-align: center;
            align-items: self-end;
        }

        .info-wrap {
            width: 80%;

            @media screen and (max-width: 1200px) {
                width: 100%;
            }

            h1 {
                color: var(--main-color);
                font-size: 51px;
                font-weight: 700;
                line-height: 62px;
                margin: 18px 0 10px;
                padding-left: 70px;
                position: relative;

                @media screen and (max-width: 1200px) {
                    text-align: center;
                    padding-left: 0;
                }

                span {
                    font-size: 43px;
                    display: block;
                    color: var(--txt-color);
                }

                @media screen and (min-width: 1200px) {
                    ::before {
                        background-color: var(--main-color);
                        border-radius: 10px;
                        content: "";
                        height: 4px;
                        left: 0;
                        position: absolute;
                        top: 29px;
                        width: 40px;
                    }
                }
            }

            p {
                padding-top: 1rem;
                color: var(--txt-color);
                font-size: 18px;
                line-height: 40px;

                @media screen and (max-width: 1200px) {
                    padding-left: 1rem;
                    padding-right: 1rem;
                }
                @media screen and (max-width: 500px) {
                    line-height: 30px;
                }
            }

            .info-button {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 2rem;
                padding-bottom: 6rem;

                button {
                    margin: 0 1rem;
                    cursor: pointer;
                    width: 10em;
                    position: relative;
                    height: 3.5em;
                    border: 3px ridge var(--main-color);
                    outline: none;
                    background-color: transparent;
                    color: var(--txt-color);
                    transition: 1s;
                    border-radius: 0.3em;
                    font-size: 16px;
                    font-weight: bold;

                    &::after {
                        content: "";
                        position: absolute;
                        top: -10px;
                        left: 3%;
                        width: 95%;
                        height: 40%;
                        background-color: var(--bg-color);
                        transition: 0.5s;
                        transform-origin: center;
                    }

                    &::before {
                        content: "";
                        transform-origin: center;
                        position: absolute;
                        top: 80%;
                        left: 3%;
                        width: 95%;
                        height: 40%;
                        background-color: var(--bg-color);
                        transition: 0.5s;
                    }

                    &:hover {
                        box-shadow: inset 0px 0px 25px var(--main-color);
                    }
                }

                button:hover::before,
                button:hover::after {
                    transform: scale(0)
                }
            }

            .info-button-first {
                margin-top: 2rem;
                padding-bottom: 0rem;
                display: flex;
                justify-content: center;
                align-items: center;

                button {
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    font-family: inherit;
                    font-weight: 500;
                    font-size: 17px;
                    padding: 0.8em 1.3em 0.8em 0.9em;
                    color: white;
                    background: transparent;
                    border: 2px solid var(--main-color);
                    letter-spacing: 0.05em;
                    border-radius: 16px;
                    transition: .5s;
                    color: var(--txt-color);
    
                    i {
                        margin-right: 3px;
                        transform: rotate(-90deg);
                        transition: transform 0.5s cubic-bezier(0.76, 0, 0.24, 1);
                    }
    
                    span {
                        transition: transform 0.5s cubic-bezier(0.76, 0, 0.24, 1);
                    }
    
                    &:hover {
                        background-color: var(--main-color);
                        transition: .5s;
                        color: var(--reverse-color);
                        transform: scale(1.1);
                        -webkit-transform:scale(1.1);
                    }
    
                    &:hover i {
                        transform: translateX(5px) rotate(0deg);
                    }
    
                    &:hover span {
                        transform: translateX(7px);
                    }
                }
            }

        }
    }
}