html {
    scroll-behavior: smooth;
}

body.dark-theme {
    --bg-color: #111;
    --main-color: #0099ff; //#ffb400
    --txt-color: #fff;
    --toggle-color: #252525;
    --nav-bg-color: #2b2a2a;
    --cursor-color: 255, 199, 64;
    --title-bg: hsla(0, 0%, 100%, .07);
    --reverse-color: #fff;
    --notif-color: #717070;
}

body.light-theme {
    --bg-color: #fff;
    --main-color: #77D970; //#72b626
    --txt-color: #666;
    --toggle-color: #000;
    --nav-bg-color: #eee;
    --cursor-color: 149, 200, 92;
    --title-bg: rgba(30, 37, 48, .07);
    --reverse-color: #000;
    --notif-color: #717070;
}

body {
    background-color: var(--bg-color);
    transition: .5s;
    overflow-x: hidden;
}

::-webkit-scrollbar {
    width: 6px;
}

::-webkit-scrollbar-thumb {
    background: var(--main-color);
    background-clip: content-box;
    border-radius: 20px;
}

::-webkit-scrollbar-track {
    background: transparent;
}

.static {
    padding: 0 5rem;
    height: 100vh;
    position: relative;

    @media screen and (max-width: 1200px) {
        padding: 0;
    }

    .header {
        cursor: default;
        align-items: center;
        display: flex;
        font-family: Poppins, sans-serif;
        justify-content: center;
        padding: 5rem 0;
        position: relative;
        text-transform: uppercase;
        width: 100%;
        overflow-x: hidden;

        h1 {
            color: var(--main-color);
            font-size: 56px;
            font-weight: 900;
            text-align: center;

            span:first-child {
                color: var(--txt-color);
            }
        }

        >span {
            color: var(--title-bg);
            font-size: 110px;
            font-weight: 800;
            left: 50%;
            letter-spacing: 10px;
            line-height: .7;
            position: absolute;
            top: 50%;
            -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
        }
    }

}

.toggle-theme-line{
    position: fixed;
    top: 0;
    right: 3.5rem;
    @media screen and (max-width: 1200px) {
        right: 2.5rem;
    }
    @media screen and (max-width: 600px) {
        right: 2rem;
    }
    transition: all .5s;
    .line{
        display: block;
        width: .3rem;
        height: 3.3rem;
        background-color: var(--toggle-color);
    }
}

.toggle-theme {
    position: fixed;
    top: 2rem;
    right: 2rem;
    transition: all .5s;

    @media screen and (max-width: 1200px) {
        top: 1rem;
        right: 1rem;
    }

    button {
        cursor: pointer;
        border: none;
        border-radius: 50%;
        width: 3.5rem;
        height: 3.5rem;
        background-color: var(--toggle-color);
        transition: all .5s;

        i {
            font-size: 2rem;
            color: #fff;
            transition: all .5s;
        }

        @media screen and (max-width: 600px) {
            width: 2.5rem;
            height: 2.5rem;

            i {
                font-size: 1.3rem;
            }
        }
    }
}

.nav {
    position: fixed;
    z-index: 999999;

    .bg-mobile {
        display: none;
    }

    @media screen and (min-width: 1200px) {
        right: 3rem;
        top: 35%;
    }

    @media screen and (max-width: 1200px) {
        left: 0;
        bottom: 0;
        display: flex;
        justify-content: space-around;
        width: 100%;
        // background-color: #2b2a2a;
        background-color: transparent;

        .bg-mobile {
            display: inline-block;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: #2b2a2a;
            clip-path: ellipse(66% 60% at 50% 100%);
        }
    }

    .nav-item {
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        width: 3.5rem;
        height: 3.5rem;
        @media screen and (max-width: 500px) {
            width: 2.5rem;
            height: 2.5rem;
        }
        border-radius: 50%;
        border: none;
        text-decoration: none;
        background-color: #2b2a2a;
        margin: 1rem 0;
        ;
        transition: .5s;

        @media screen and (max-width: 1200px) {
            background-color: #444;
            margin: .3rem 0;
        }

        i {
            font-size: 1.5rem;
            z-index: 2;
        }

        position: relative;

        .name {
            display: none;
        }

        @media screen and (min-width: 1200px) {
            .name {
                display: block;
                position: absolute;
                right: 2rem;
                top: 0;
                background-color: var(--main-color);
                padding: 1.1rem;
                padding-right: 2rem;
                border-radius: 100px 0 0 100px;
                color: white;
                visibility: hidden;
                opacity: 0;
                transition: visibility 0.5s linear, opacity 0.1s linear;
                z-index: 1;
            }

            &:hover {
                .name {
                    visibility: visible;
                    opacity: 1;

                }
            }
        }

        &:hover {
            color: white;
            transition: .1s;
            background-color: var(--main-color);
        }
    }

    .active {
        background-color: var(--main-color);

        i {
            color: white;
        }
    }
}